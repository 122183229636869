import React from "react";

import FooterNewsletterSubscribe from "components/NewsletterSubscribeForm/FooterNewsletterSubscribe";

const MailSubscribe = () => {
  return (
    <div className="w-full max-w-xl">
      <div className="my-6">
        Subscribe today for BTQ investor updates delivered straight to your inbox.
      </div>
      <FooterNewsletterSubscribe />
    </div>
  );
};

export default MailSubscribe;
