import React, { ReactNode } from "react";
import { Footer } from "../Footer";
import { Header } from "../Header";

import COLOR from "constants/design";

import { ScrollWrapper } from "./ScrollWrapper";

interface LayoutProps {
  children: ReactNode;
  background?: {
    color?: string;
    component?: React.ReactNode;
  };
  noHeader?: boolean;
  noFooter?: boolean;
}

const Layout = ({ children, background, noHeader = false, noFooter = false }: LayoutProps) => {
  // for position and RWD

  return (
    <ScrollWrapper>
      <div
        className="flex flex-col text-white"
        style={{
          backgroundColor: background?.color || COLOR.DEFAULT_BG_COLOR,
        }}
      >
        {/* background-image */}
        {background?.component && <div className="fixed inset-0">{background?.component}</div>}

        {!noHeader && <Header />}
        <main className="flex-1">{children}</main>
        {!noFooter && <Footer className="flex-none" />}
      </div>
    </ScrollWrapper>
  );
};

export { Layout };
