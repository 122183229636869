enum SCROLL {
  RESTORED_POSITION = "restoredPosition",
}

type ExistedRestoredPosition = { [pathname: string]: string };
type RestoredPosition = ExistedRestoredPosition | null;

function getRestoredPosition() {
  const store = sessionStorage.getItem(SCROLL.RESTORED_POSITION);
  return (store ? JSON.parse(store) : null) as RestoredPosition;
}

function getRestoredPositionByPathname(pathname: string) {
  const restoredPosition = getRestoredPosition();
  return restoredPosition ? +restoredPosition[pathname] : 0;
}

function replaceRestoredPosition(
  existedRestoredPosition: ExistedRestoredPosition,
  pathname: string,
  position: string
) {
  const updatedRestoredPosition = JSON.stringify({
    ...existedRestoredPosition,
    [`${pathname}`]: position,
  });
  sessionStorage.setItem(SCROLL.RESTORED_POSITION, updatedRestoredPosition);
}

function pushRestoredPosition(
  existedRestoredPosition: RestoredPosition,
  pathname: string,
  position: string
) {
  const newRestoredPosition = {
    [`${pathname}`]: position,
  };
  sessionStorage.setItem(
    SCROLL.RESTORED_POSITION,
    JSON.stringify(
      existedRestoredPosition
        ? { ...existedRestoredPosition, ...newRestoredPosition }
        : newRestoredPosition
    )
  );
}

function updateRestoredPosition(pathname: string, position: string) {
  const restoredPosition = getRestoredPosition();

  const hasExistedPathname = restoredPosition && restoredPosition[pathname];

  if (hasExistedPathname) {
    replaceRestoredPosition(restoredPosition, pathname, position);
    return;
  }

  pushRestoredPosition(restoredPosition, pathname, position);
}

export { getRestoredPosition, getRestoredPositionByPathname, updateRestoredPosition };
