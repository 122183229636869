import React, { useState } from "react";
import { Section } from "components/Section";

import CapitalStructure from "./capitalStructure";
import Reportings from "./reporting";
import MailSubscribe from "./subscription";
import Documents from "./documents";

enum Tabs {
  CAPITAL_STRUCTURE,
  REPORTING,
  MAIL_SUBSCRIPTION,
  DOCUMENTS_DOWNLOAD,
}

const TabItem = (props: { title: string; onClick: () => void; isSelected: boolean }) => (
  <div
    className={`inline-block px-5 py-2  ${
      props.isSelected && "border-b-2 border-cyan-400"
    } cursor-pointer`}
    onClick={props.onClick}
  >
    {props.title}
  </div>
);

const TableContent: {
  [key in Tabs]: React.ReactNode;
} = {
  [Tabs.CAPITAL_STRUCTURE]: <CapitalStructure />,
  [Tabs.REPORTING]: <Reportings />,
  [Tabs.MAIL_SUBSCRIPTION]: <MailSubscribe />,
  [Tabs.DOCUMENTS_DOWNLOAD]: <Documents />,
};

const DataTable = () => {
  const [selectedTab, setSelectedTab] = useState<Tabs>(Tabs.CAPITAL_STRUCTURE);

  const tableContent = TableContent[selectedTab];

  return (
    <Section className="my-20 py-10">
      <div className="text-center">
        <TabItem
          title="CAPITAL STRUCTURE"
          onClick={() => setSelectedTab(Tabs.CAPITAL_STRUCTURE)}
          isSelected={selectedTab === Tabs.CAPITAL_STRUCTURE}
        />
        <TabItem
          title="REPORTING"
          onClick={() => setSelectedTab(Tabs.REPORTING)}
          isSelected={selectedTab === Tabs.REPORTING}
        />
        <TabItem
          title="NEWSLETTER"
          onClick={() => setSelectedTab(Tabs.MAIL_SUBSCRIPTION)}
          isSelected={selectedTab === Tabs.MAIL_SUBSCRIPTION}
        />
        <TabItem
          title="DOCUMENTS"
          onClick={() => setSelectedTab(Tabs.DOCUMENTS_DOWNLOAD)}
          isSelected={selectedTab === Tabs.DOCUMENTS_DOWNLOAD}
        />
      </div>
      <div className="my-5 flex justify-center">{tableContent}</div>
    </Section>
  );
};

export default DataTable;
