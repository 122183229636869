import * as React from "react";
import { PageProps } from "gatsby";

import Hero from "views/investorRelations/hero";
import DataTable from "views/investorRelations/dataTable";
import Faq from "views/investorRelations/faq";

import withI18n from "i18n/withI18n";
import { Layout } from "components/Layout";
import SEO from "components/seo";
import COLOR from "constants/design";

const InvestorRelations = ({ location }: PageProps) => {
  //   const data = useStaticQuery(query);
  //   console.dir(data);
  return (
    <>
      <SEO title="BTQ - Investor Relations" pathname={location.pathname} locale />
      <Layout background={{ color: COLOR.BACKGROUND_DARK }}>
        <Hero />
        <DataTable />
        <Faq />
      </Layout>
    </>
  );
};

export default withI18n(InvestorRelations);
