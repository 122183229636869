import React from "react";
import { FormattedMessage } from "react-intl";

import { Section } from "components/Section";
import BgImg from "views/home/hero/background";
import { Button } from "components/Button";

const InvestorHero = () => (
  <Section background={{ component: BgImg }}>
    <div className="flex h-screen flex-col items-start justify-center">
      <h1 className="text-6xl font-bold sm:text-8xl">
        <FormattedMessage id="INVESTOR_RELATIONS_HERO_TITLE" />
      </h1>
      <div className="mb-4 w-full sm:mb-8 sm:w-[40rem] sm:text-xl">
        <FormattedMessage id="HOME_HERO_SUBTITLE" />
      </div>
      <a
        href={`/${encodeURIComponent("BTQ Investor Deck - February 2023.pdf")}`}
        target="_blank"
        rel="noreferrer"
      >
        <Button className="mt-4 w-60 sm:w-80">
          <FormattedMessage id="INVESTOR_RELATIONS_DOWNLOAD_DECK" />
        </Button>
      </a>
    </div>
  </Section>
);

export default InvestorHero;
