import React from "react";

const LinkToFile = ({ filePath }: { filePath: string }) => (
  <a
    href={`/${encodeURIComponent(filePath)}`}
    target="_blank"
    rel="noreferrer"
    className="hover:underline"
  >
    {filePath}
  </a>
);

const Documents = () => (
  <div className="w-full rounded-xl bg-btq-card-bg-light p-10 sm:px-24 md:w-3/4">
    <ul className="flex flex-col gap-y-3 md:gap-y-7">
      <li>
        <LinkToFile filePath="Certificate of Name Change.pdf" />
      </li>
      <li>
        <LinkToFile filePath="BTQ Technologies Corp - NOA.pdf" />
      </li>
      <li>
        <LinkToFile filePath="Articles.pdf" />
      </li>
    </ul>
  </div>
);

export default Documents;
