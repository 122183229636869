import React, { FC, HTMLProps, useRef } from "react";

import {
  useRestorePositionForDiffLocale,
  useRecordScrollingPosition,
  usePreventMobileTouchResize,
} from "./hooks";

const ScrollWrapper: FC<HTMLProps<HTMLDivElement>> = ({ children }) => {
  const layoutRef = useRef<HTMLDivElement>(null);

  const recordScrollingPosition = useRecordScrollingPosition(layoutRef);
  useRestorePositionForDiffLocale(layoutRef);
  usePreventMobileTouchResize(layoutRef);

  return (
    <div
      onScroll={recordScrollingPosition}
      ref={layoutRef}
      className="h-screen overflow-y-auto overflow-x-hidden"
    >
      {children}
    </div>
  );
};

export { ScrollWrapper };
