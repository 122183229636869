import * as React from "react";

import { Section } from "components/Section";
import { Title } from "components/Title";
import COLOR from "constants/design";

const FAQ_ITEMS = [
  [
    "Is BTQ a publicly traded company?",
    "Yes. BTQ Technologies Corp. is listed and trading on the NEO Exchange in Canada, under the ticker “BTQ”.",
  ],
  [
    "When was BTQ founded?",
    "BTQ was originally founded as a private Liechtenstein entity in March, 2021, but later underwent a change of structure whereby it became a public Canadian entity in February, 2023.",
  ],
  [
    "Where is BTQ located?",
    "The headquarters of BTQ Technologies Corp. is located in Vancouver, BC, but the company also has a wholly owned subsidiary in Vaduz, Liechtenstein, which in turn has a branch office in Taipei, Taiwan. The Taiwan office serves as the company’s technical hub, with a team of active researchers. BTQ also maintains a small but growing presence in Sydney, Australia, and Tokyo, Japan, and is actively expanding in both countries.",
  ],
  [
    "When is BTQ’s fiscal year-end?",
    "BTQ’s financial year-end is December 31. This information is also publicly available in the company’s profile on www.sedar.com",
  ],
  [
    "How can I learn more about BTQ’s financial statements?",
    "Information about BTQ’s finances can be found in the company’s public SEDAR filings at www.sedar.com, along with other important information about the company.",
  ],
  [
    "Where can I find information about BTQ’s executive team?",
    "The company’s website at www.btq.com contains key information of this kind, and is continually updated.",
  ],
  [
    "Who is BTQ’s legal counsel?",
    "BTQ’s Chief Legal Officer is Peter Lavelle, but the company has also retained Farris LLP, Winkler Partners, and Batliner Wanger Batliner Rechtsanwälte AG as its local counsel for matters specific to Canada, Taiwan, and Liechtenstein, respectively.",
  ],
];

const Item = (props: { q: string; a: string }) => (
  <div className="mt-12">
    <div className="text-xl text-primary">{props.q}</div>
    <div className="mt-2">{props.a}</div>
  </div>
);

const Faq = () => {
  return (
    <Section background={{ color: COLOR.BACKGROUND_LIGHT }} className="py-20">
      <Title className="items-center">FAQs</Title>
      <div className="mx-auto w-full max-w-[50rem] ">
        {FAQ_ITEMS.map(([q, a], index) => (
          <Item q={q} a={a} key={index} />
        ))}
      </div>
    </Section>
  );
};

export default Faq;
