import React from "react";
// import { useCapitalStructure } from "./hooks";

const Item = (props: { title: string; value: string }) => (
  <div>
    <div>{props.title}</div>
    <div className="text-2xl text-cyan-400">{props.value}</div>
  </div>
);

const CapitalStructure = () => {
  // const data = useCapitalStructure();

  return (
    <div className="w-full rounded-xl bg-btq-card-bg-light p-10  sm:px-24">
      <div className="grid grid-cols-1 gap-12 md:grid-cols-2">
        <Item title="Ticker Symbols" value="NEO: BTQ, FSE: NG3" />
        {/* <Item title="Listing Date" value="February 21, 2023" /> */}
        <Item title="Total Shares Outstanding" value="121,248,867" />
        <Item title="Options and Warrants Outstanding" value="8,217,937" />
        <Item title="Fully Diluted Shares Outstanding" value="129,466,204" />

        {/* <Item title="Cash & Illiquid assets" value={data.cashAndIlliquidAssets} /> */}
        {/* <Item title="Market Capitalization" value={data.marketCapitalization} /> */}
        {/* <Item title="Warrants" value={data.warrants} /> */}
        {/* <Item title="Fully Diluted Shares Outstanding" value={data.fullyDilutedSharesOutstanding} /> */}
        {/* <Item title="52-Week Low/High" value={data.fiftyTwoWeekLowHigh} /> */}
        {/* <Item title="Cash and Liquid Assets" value={data.cashAndLiquidAssets} /> */}
      </div>
    </div>
  );
};

export default CapitalStructure;
