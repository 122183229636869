import React from "react";
// import pdfIcon from "assets/pdf.png";

// import { useReportings } from "./hooks";

// const PdfDownload = (props: { link: string }) => (
//   <a href={props.link} target="_blank" rel="noreferrer">
//     <img className="h-6 cursor-pointer" src={pdfIcon} alt="pdf download" />
//   </a>
// );

const Reportings = () => {
  // const data = useReportings();

  return (
    <div className="mt-8 w-full overflow-y-auto">
      <table className="w-full min-w-[50rem] table-auto">
        <thead className="text-left text-cyan-500">
          <tr>
            <th className="p-4">Date</th>
            <th>Quarter</th>
            <th>Financials</th>
            <th>MD&A</th>
          </tr>
        </thead>
        <tbody className="bg-btq-card-bg-light">
          <tr className="border-b-1 bg-btq-card-bg-light">
            <td className="p-4"></td>
            <td></td>
            <td>{/* <PdfDownload link={report.financials.asset.url} /> */}</td>
            <td>{/* <PdfDownload link={report.managementDicussionAndAnalysis.asset.url} /> */}</td>
          </tr>
          {/* {data.map((report, index) => (
            <tr key={index} className="border-b-1 bg-btq-card-bg-light">
              <td className="p-4">{report.financialReportsDate}</td>
              <td>{report.quarter}</td>
              <td>
                <PdfDownload link={report.financials.asset.url} />
              </td>
              <td>
                <PdfDownload link={report.managementDicussionAndAnalysis.asset.url} />
              </td>
            </tr>
          ))} */}
        </tbody>
      </table>
    </div>
  );
};

export default Reportings;
