import { useLayoutEffect, useCallback, RefObject, useEffect } from "react";

import { useDebounce } from "hooks/useDebounce";
import { getRestoredPositionByPathname, updateRestoredPosition } from "./utils";
import { getPathStringWithoutLocale } from "utils/url";

const useRestorePositionForDiffLocale = (layoutRef: RefObject<HTMLDivElement>) => {
  useLayoutEffect(() => {
    const pathname = getPathStringWithoutLocale(window.location.pathname);
    const scrollPosition = getRestoredPositionByPathname(pathname);
    layoutRef.current?.scrollTo(0, scrollPosition);
  }, [layoutRef]);
};

const useRecordScrollingPosition = (layoutRef: RefObject<HTMLDivElement>) => {
  const storePositionToSession = useCallback(() => {
    const pathname = getPathStringWithoutLocale(window.location.pathname);
    updateRestoredPosition(pathname, `${layoutRef.current?.scrollTop}`);
  }, [layoutRef]);
  const recordScrollingPosition = useDebounce(storePositionToSession);

  return recordScrollingPosition;
};

interface TouchEventWithScale extends TouchEvent {
  scale?: number; // TouchEvent doesn't include scale
}
type TouchListener = (e: TouchEvent) => void;

const touchListener: TouchListener = (event: TouchEventWithScale) => {
  if (event.scale === undefined || event.scale <= 1) return;

  event.preventDefault();
};

const usePreventMobileTouchResize = (layoutRef: RefObject<HTMLDivElement>) => {
  useEffect(() => {
    const layout = layoutRef.current;
    layout?.addEventListener("touchmove", touchListener);
    return () => {
      layout?.removeEventListener("touchmove", touchListener);
    };
  }, [layoutRef]);
};

export { useRestorePositionForDiffLocale, useRecordScrollingPosition, usePreventMobileTouchResize };
